import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';
import { IParticipantSectionsContext } from './ParticipantSectionsContext';
import flatten from 'lodash/flatten';
import { getSectionNavigationInfo, loadParticipantSections } from './helpers';
import {
  getStepNavigationInfo,
  loadParticipantSteps,
  updateParticipantStepStatus,
} from '../ParticipantStepsDataProvider/helpers';
import { isSelfPaced } from '../../selectors/isSelfPaced';
import memoize from 'lodash/memoize';
import { getChallengeData } from '../storage-contexts/Challenge';
import {
  addOnLoginHandler,
  IOnLoginHandlerPriority,
} from '../GeneralDataProvider/helpers/onLogin';
import { isV3enabled } from '../../experiments/isV3enabled';
import { getParticipantSectionsV3 } from './GetParticipantSectionsV3';
import { requestParticipantStep } from './requestParticipantStep';
import { requestParticipantSection } from './requestParticipantSection';
import { ParticipantStep } from '../../types/v3Types';

const updateParticipantsSections = async (flowAPI: ControllerFlowAPI) => {
  const _sections = await loadParticipantSections(flowAPI);
  const _steps = flatten<ParticipantStep>(
    _sections.map((section) => section.steps || []),
  );

  flowAPI.controllerConfig.setProps({
    listParticipantSections: _sections,
    participantSteps: { steps: _steps },
  });
};

const loadSectionsV1 = async (flowAPI: ControllerFlowAPI) => {
  const [sections = [], stepsResponse] = await Promise.all([
    loadParticipantSections(flowAPI),
    loadParticipantSteps(flowAPI),
  ]);
  const steps = stepsResponse?.steps?.length
    ? stepsResponse.steps
    : flatten<ParticipantStep>(sections.map((section) => section?.steps || []));
  return { steps, sections };
};

const handleSectionsAfterLogin = () => {
  addOnLoginHandler({
    priority: IOnLoginHandlerPriority.SECONDARY,
    handler: async (flowAPI: ControllerFlowAPI) => {
      const challengeData = await getChallengeData(flowAPI);
      if (isV3enabled(flowAPI)) {
        const { sections, steps } = await getParticipantSectionsV3(
          challengeData?.challenge,
          flowAPI,
        );
        return flowAPI.controllerConfig.setProps({
          listParticipantSections: sections,
          participantSteps: { steps },
          isListParticipantSectionsRequestInProgress: false,
        });
      }
      if (isSelfPaced(challengeData?.challenge)) {
        await updateParticipantsSections(flowAPI);
      } else {
        flowAPI.controllerConfig.setProps({
          participantSteps: await loadParticipantSteps(flowAPI),
        });
      }
    },
  });
};

export const participantSectionsPropsMap = memoize(async function (
  flowAPI: ControllerFlowAPI,
): Promise<IParticipantSectionsContext> {
  let sections: IParticipantSectionsContext['listParticipantSections'];
  let steps: IParticipantSectionsContext['participantSteps']['steps'];

  if (isV3enabled(flowAPI)) {
    const challengeData = await getChallengeData(flowAPI);
    const challenge = challengeData?.challenge;
    ({ sections, steps } = await getParticipantSectionsV3(challenge, flowAPI));
  } else {
    ({ sections, steps } = await loadSectionsV1(flowAPI));
  }
  const [selectedSection, { selectedStep }] = await Promise.all([
    getSectionNavigationInfo(flowAPI, sections),
    getStepNavigationInfo(flowAPI, steps),
  ]);

  void handleSectionsAfterLogin();

  return {
    selectedStep,
    selectedSection,
    participantSteps: { steps },
    listParticipantSections: sections,
    isListParticipantSectionsRequestInProgress: false,
    isParticipantStepsLoading: false,
    updateParticipantSections: () => updateParticipantsSections(flowAPI),
    updateParticipantSteps: async () => {
      flowAPI.controllerConfig.setProps({
        participantSteps: await loadParticipantSteps(flowAPI),
      });
    },
    updateParticipantStepStatus: async (payload) =>
      updateParticipantStepStatus(flowAPI, payload),
    requestParticipantSection: async (sectionId, lSections) =>
      requestParticipantSection(sectionId, lSections, flowAPI),
    requestParticipantStep: async (stepId, lSteps) =>
      requestParticipantStep(stepId, lSteps, flowAPI),
  };
});
